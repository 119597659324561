import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, MarkerLabel } from 'react-google-maps';
class Map extends Component {


  // Getting data from API into state: https://reactjs.org/docs/faq-ajax.html
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      locations: []
    };
  }

  componentDidMount() {
    this.timer = setInterval(
      fetch('https://k9nx1ljnz8.execute-api.us-west-2.amazonaws.com/default/serveSpotData?messengerId=0-3105611&messages=20')
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              locations: result.body.features
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        ),
      10000, // refresh map every 10 seconds
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  } 

  renderMarkers() {
    return this.state.locations.map((location, i) => {
      var markerIcon = "https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_8x.png&scale=0.75"
      if (i == 0) {
        markerIcon = "https://mt.google.com/vt/icon/name=icons/transit/tactile/triplabel/travelmode/drive.png&scale=1.5"
      }
      return <Marker
        key={ i }
        onClick = { this.onMarkerClick }
        position = {{ lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] }}
        options={{
          icon:`${markerIcon}`,
          //label: <MarkerLabel
          //  text= "test"
          ///>
          //label: `${location.properties.dateTime}`
        }}
        />
    })
  }

  render() {
    const VanMap = withGoogleMap(props => (
      <GoogleMap
        defaultCenter = { { lat: this.state.locations[0].geometry.coordinates[1], lng: this.state.locations[0].geometry.coordinates[0] } }
        defaultZoom = { 13 }
      >
        { this.renderMarkers() } 
      </GoogleMap>
    ));
  
    return(
    <div>
      { this.state.locations.length &&
      <VanMap
        containerElement={ <div style={{ height: `100vh` }} /> }
        mapElement={ <div style={{ height: `100%` }} /> }
      /> }
    </div>
  );
  }
};
export default Map;